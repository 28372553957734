import React from 'react';

import Layout from 'components/core/layout';
import Seo from 'components/core/seo';
import P from 'components/core/p';
import WorkItem from 'components/pages/work/WorkItem';
import Span from 'components/core/span';
import A from 'components/core/a';

const Work: React.FC = () => {
  return (
    <Layout>
      <Seo title="Work" />
      <P>
        References will be available{' '}
        <Span>
          <A href="mailto: ctuncelli@yahoo.com" text="upon request" />
        </Span>
        .
      </P>
      <ul>
        <WorkItem
          role="Software Engineer"
          name="Vibely Inc."
          location="Menlo Park, CA"
          description={`Solved complex problems at scale to build and improved web and mobile experience for Vibely users. Was eligible to switch between different contexts. Came up with new ideas in order to increase UX, worked with designers very closely. Contributed to every product that Vibely had, and worked closely with various stakeholders including product, design, and communication teams. Implemented key features in order to increase app performance. Shipped features that thousands of people used every day.\n\nVibely was backed by YouTube Founder Steve Chen, Meetup founder Scott Heiferman, Asana Cofounder & Netflix’s Social Dilemma Co-creator Justin Rosenstein, Lean Startup Author Eric Ries, Kissmetrics Founder Hiten Shah, Snapchat/Facebook/Twitter leads, Turner Novak, Matthew Kobach, Ian Borthwick, and renowned DJ 3LAU.\n\n→ Technologies: TypeScript (React, React Native, NodeJS), AWS, PostgreSQL, GraphQL, HTML, CSS.`}
          websiteHref="https://vibely.io"
          history="June 2021 – Aug 2022"
        />
        <WorkItem
          role="Founder and Full Stack Developer"
          name="Snapcard"
          location="Izmir, TR"
          description={`Designed and developed scalable web and mobile applications for sharing and saving contact information by using encrypted QR code. Shipped new features every week for 15+ countries that uses Snapcard. Precisely documented the code, used Agile practices, and Test Driven Development techniques to provide reliable, working software early and often. Built strategic marketing and business development plans. Used complex reporting/tracking tools to gather and evaluate the data to perfect the user experience and improve the performance of the systems we use.\n\n→ Technologies: TypeScript (NodeJS, React), HTML, TailwindCSS, MongoDB, Firebase`}
          history="June 2020 – Nov 2020"
        />
        <WorkItem
          role="Software Engineering Intern"
          name="Univerlist"
          location="Izmir, TR"
          description={`Improved the Univerlist experience. Shipped features for thousands of people every day. Contributed to developing a University Panel for university admins. Played a key role in a team to create a new mobile app for university guidelines. Successfully created scalable architecture consisting of a backend server, DB, and cache. Developed RESTful API with Django.\n\n→ Technologies: JS (React, React Native), Django, HTML, CSS, PostgreSQL, Redis, Firebase`}
          websiteHref="https://univerlist.com"
          history="Aug 2018 – Nov 2019"
        />
      </ul>
    </Layout>
  );
};

export default Work;
