import * as React from 'react';
import PropTypes from 'prop-types';

const SectionContainer = ({ children }) => {
  return (
    <>
      <div className="pt-8">{children}</div>
    </>
  );
};

SectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionContainer;
