import React from 'react';
import Link from 'components/core/a';
import H3 from 'components/core/h3';
import P from 'components/core/p';
import SectionContainer from 'components/core/section-container';

type WorkItemArgsType = {
  role: string;
  name: string;
  location: string;
  history: string;
  websiteHref?: string;
  description: string;
};

const WorkItem: React.FC<WorkItemArgsType> = ({ role, description, name, location, history, websiteHref }) => {
  return (
    <li>
      <SectionContainer>
        <div className="flex justify-between">
          <div>
            <div className="flex items-baseline">
              <H3 additionalStyle={'pr-1 text-sm'}>{role}</H3>
              {'–'}
              <Link href={websiteHref || '#'} additionalStyle={'pl-1 text-gray-700 text-sm leading-6'} text={name} />
              <P additionalStyle={'pl-1'}>{location}</P>
            </div>
          </div>
          <P>{history}</P>
        </div>
        <P additionalStyle={'whitespace-pre-wrap'}>{description}</P>
      </SectionContainer>
    </li>
  );
};

export default WorkItem;
