import * as React from 'react';
import PropTypes from 'prop-types';

const Span = ({ children }) => {
  return (
    <>
      <span className="text-gray-700 text-sm">{children}</span>
    </>
  );
};

Span.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Span;
