import * as React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const H3 = ({ children, additionalStyle }) => {
  return (
    <>
      <h3 className={cn('font-bold', additionalStyle)}>{children}</h3>
    </>
  );
};

H3.propTypes = {
  children: PropTypes.node.isRequired,
  additionalStyle: PropTypes.string,
};

export default H3;
