import * as React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const A = ({ href, text, additionalStyle }) => {
  return (
    <a href={href} target="_blank" rel="noreferrer" className={cn('text-blue-600 inline', additionalStyle)}>
      {text}
    </a>
  );
};

A.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  additionalStyle: PropTypes.string,
};

export default A;
